@import "~@/assets/scss/variables";













































































































































@import '~@/assets/scss/variables';

.ffs-header {
  margin-bottom: 4px;
  display: flex;
  align-items: baseline;
  border-top: 1px solid var(--wizard-step-line-color-lg, $wizard-step-line-color-lg);

  &__title {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    flex: 1 1;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__actions {
    // min-width: 5px;
    // min-height: 34px;
    text-align: right;
    color: var(--triggers-filter-action-link-color, $triggers-filter-action-link-color);
    font-family: var(--font-heading, $font-heading);
    text-transform: uppercase;
    font-weight: bold;
    line-height: 100%;

    a {
      display: inline-block;
      padding: 10px 0;
      color: currentColor;
      letter-spacing: 1px;

      &:hover {
        text-decoration: underline;
        color: var(--triggers-filter-action-link-hover-color, $triggers-filter-action-link-hover-color);
      }
    }
  }
}
